import request from '@/utils/request'

// 获取订单列表
export function QueryPagedOrders(data) {
    return request({
        url: '/api/services/app/LeaseOrder/QueryPagedOrders',
        method: 'post',
        data
    })
}

// 获取订单详情
export function GetOrderDetail(data) {
    return request({
        url: '/api/services/app/LeaseOrder/QueryInfo',
        method: 'post',
        data
    })
}

// 统计订单金额
export function QueryOrderStatistics(data) {
    return request({
        url: '/api/services/app/LeaseOrder/QueryOrderStatistics',
        method: 'post',
        data
    })
}

// 查询代理、商家预期收益
export function GetExpectRevenue(data) {
    return request({
      url: '/api/services/app/LeaseOrder/GetExpectRevenue',
      method: 'get',
      params: data
    })
}

// 取消订单
export function CancelOrder(data) {
    return request({
        url: '/api/services/app/LeaseOrder/CancelOrder',
        method: 'post',
        data
    })
}

// 如果是使用中的订单----结束订单
// 如果是已归还的订单----执行扣款和完成操作
export function CompleteOrder(data) {
    return request({
        url: '/api/services/app/LeaseOrder/CompleteOrder',
        method: 'post',
        data
    })
}

// 订单退款
export function OrderRefund(data) {
    return request({
        url: '/api/services/app/LeaseOrder/OrderRefund',
        method: 'post',
        data
    })
}

// 更新所有订单的报警器ID为新的
export function PositionUpdate(data) {
    return request({
      url: '/api/services/app/LeaseOrder/UpdateTerminalToNew',
      method: 'put',
      data
    })
}

// 信息同步（可上报违约）
export function CreditReport(data) {
    return request({
        url: '/api/Alipay/CreditReport',
        method: 'post',
        data
    })
}

