<template>
    <div class="page">
        <div>
            <div class="fr">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item>
                        <el-select v-model="filter.statisticsType" placeholder="" size="medium">
                            <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker v-model="dateRange" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" @change="timeVoi"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.agentId" filterable remote reserve-keyword placeholder="代理(输入搜索)" :remote-method="renderAgentOptions" clearable
                            @change="_search()">
                            <el-option v-for="item in agentItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.merchantId" filterable remote reserve-keyword placeholder="选择商家" :remote-method="renderMerchantOptions" clearable
                            @change="_search()">
                            <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="_search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="clearb"></div>
        </div>
        <el-table :data="tableData" border fit highlight-current-row>
            <el-table-column label="日期" prop="dateDesc"></el-table-column>
            <el-table-column label="流水金额" prop="amount"></el-table-column>
            <el-table-column label="订单数量" prop="count"></el-table-column>
            <!--<el-table-column label="操作" width="90">
                <template slot-scope="scope">
                    <el-dropdown @command="dropdownHandleCommand">
                        <span class="el-dropdown-link">
                            更多
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-delete" :command="[_delete,[scope.row]]">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    </template>
            </el-table-column>-->
        </el-table>
    </div>
</template>

<script>
import * as funOrder from "@/api/order/index"
import * as funMerchant from "@/api/merchant/index"
import * as funAgent from "@/api/agent/index"
import moment from "moment"
export default {
    data() {
        return {
            filter: {
                agentId: null,
                merchantId: null,
                statisticsType: 1,
                startDate: "",
                endDate: ""
            },
            dateRange: [],
            tableData: [],
            agentItems: [],
            merchantItems: [],
            types: [
                { id: 1, name: "按天查询" },
                { id: 2, name: "按月查询" }
            ]
        }
    },
    created() {
        this.renderMerchantOptions("")
        this.renderAgentOptions("")
    },
    methods: {
        async getData() {
            if (!this.filter.startDate || !this.filter.endDate) {
                this.$message.warning("请选择查询时间段!");
                return false;
            }
            window.$common.fullLoading()
            await funOrder.QueryOrderStatistics(this.filter).then(res => {
                window.$common.closeFullLoading()
                this.tableData = res.map((x) => {
                    x.dateDesc = moment(x.date).format("YYYY-MM-DD")
                    if (this.filter.statisticsType === 2) {
                        x.dateDesc = moment(x.date).format("YYYY-MM")
                    }
                    return x;
                });
            })
        },
        async _delete(row) {
            this.confirm(`确认要删除商户【${row.merchantId}】的投放位置【${row.position}】吗？`).then(() => {
                window.$common.fullLoading()
                funMerchant.PositionDelete({ id: row.id }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("删除成功");
                    this.getData();
                })
            })
        },
        _refreshData(data) {
            if (data && data.id) {
                this.filter.merchantId = data.merchantId;
                this.renderMerchantOptions("");
                this._search();
            }
        },
        _search() {
            this.filter.page = 1;
            this.getData();
        },
        //日期改变
        timeVoi(value) {
            if (value != null && value.length == 2) {
                let value0 = moment(value[0])
                let value1 = moment(value[1])
                this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")
                if (value1.format("HH") == "00" &&
                    value1.format("mm") == "00" &&
                    value1.format("ss") == "00") {
                    this.filter.endDate = value1.format("YYYY-MM-DD 23:59:59")
                    this.dateRange = [
                        (this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endDate = value1.format("YYYY-MM-DD 23:59:59")),
                    ]
                } else {
                    this.filter.endDate = value1.format("YYYY-MM-DD HH:mm:ss")
                    this.dateRange = [
                        (this.filter.startDate = value0.format("YYYY-MM-DD HH:mm:ss")),
                        (this.filter.endDate = value1.format("YYYY-MM-DD HH:mm:ss")),
                    ]
                }
                this._search()
            } else {
                this.filter.startDate = ""
                this.filter.endDate = ""
                this._search()
            }
        },
        async renderAgentOptions(query) {
            await funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        }
    }
}
</script>
